import ValidationItem from './XRowCellValidationItem';
import {isNotEmpty, remove} from "@/util/objects";

export default {
    render(h) {
        return <tbody>
        {this.rows.map((row, index) => this.createRow(row, index))}
        </tbody>;
    },
    name: "XRowBody",
    inject: ['xRow'],
    components: {ValidationItem},   //using in XRowCell.vue
    props: {
        rows: {
            type: Array,
            default: []
        },
        columns: {
            type: Array,
            default: []
        },
        noCleanBtn: {
            type: Boolean,
            default: false
        },
        sortReadonly: {
            type: Boolean,
            default: false
        }
    },
    created() {
        this.watchedRow = new Set();
    },
    methods: {
        createRow(row, index) {
            if (isNotEmpty(this.xRow.watchProps) && !this.watchedRow.has(row)) {
                this.watchedRow.add(row);
                this.xRow.watchProps.forEach(prop => {
                    if (!row.hasOwnProperty(prop))
                        throw new Error(`attr: ${prop} is absent`);
                    this.$watch(() => row[prop],
                        (newVal, oldVal) => this.xRow.$emit(`${prop}Change`, newVal, oldVal, row));
                })
            }
            let tds = this.columns.map((column) => this.createCell(column, row, index));
            let setValue = (value) => {
                if (!isNaN(value)) {
                    row.sort = parseInt(value);
                }
            };
            const styleCenter = {'text-align': 'center'};
            return <tr key={row.key}>
                {!this.sortReadonly ? (<td style={styleCenter} width="60px">
                    <el-input class="sort-input"
                              value={row.sort}
                              on-input={setValue}
                              size="mini"
                              on-blur={() => this.$emit("sortChanged")}/>
                </td>) : (<td style={styleCenter} width="60px">{row.sort}</td>)}
                {!this.noCleanBtn ? (<td style={styleCenter} width='45'>
                    <i class="el-icon-delete" on-click={() => this.delRow(row)}/>
                </td>) : null}
                {tds}
            </tr>;
        },
        createCell(column, row, index) {
            return column.renderCell(this.$createElement, row, index);
        },
        delRow(obj) {
            remove(this.rows, obj);
        }
    }
}
